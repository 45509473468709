/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import FieldForm from 'Component/FieldForm';
import { countriesType } from 'Type/Config';
import {
    getAvailableRegions,
    setAddressesInFormObject
} from 'Util/Address';

/** @namespace Rma/Component/MyAccountNewReturnAddressForm/Component/MyAccountNewReturnAddressFormComponent */
export class MyAccountNewReturnAddressFormComponent extends FieldForm {
    static propTypes = {
        isSubmitted: PropTypes.bool,
        address: PropTypes.objectOf(),
        countries: countriesType.isRequired,
        addressLinesQty: PropTypes.number.isRequired,
        onSave: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        default_country: 'US',
        isSubmitted: false,
        onSave: () => {}
    };

    __construct(props) {
        super.__construct(props);

        const {
            countries,
            shippingFields,
            address,
            default_country
        } = props;

        const {
            country_id,
            region_id,
            city = ''
        } = shippingFields || address;

        const country = countries.find(({ id }) => id === country_id) || {};
        const countryId = Object.keys(country).length ? country_id : default_country;

        const { is_state_required = false } = country;

        const availableRegions = getAvailableRegions(countryId, countries);
        const defaultRegionId = availableRegions.length ? availableRegions[0].id : '';
        const regionId = region_id || defaultRegionId;

        this.state = {
            countryId,
            availableRegions,
            regionId,
            isStateRequired: is_state_required,
            city
        };
    }

    onFormSuccess = (fields) => {
        const { availableRegions } = this.state;
        const { onSave, addressLinesQty, hidePopup } = this.props;
        const { region_id = 0, region_string: region, ...newAddress } = addressLinesQty > 1
            ? setAddressesInFormObject(fields, addressLinesQty)
            : fields;

        newAddress.region = { region_id, region };

        if (availableRegions.length && region === undefined) {
            const selectedRegion = availableRegions.find((element) => element.id === parseInt(region_id, 10));
            newAddress.region = { region_id: selectedRegion.id, region: selectedRegion.name };
        }

        onSave(newAddress);
        hidePopup();
    };

    getRegionFields() {
        const { address: { region: { region } = {} }, regionDisplayAll } = this.props;
        const { availableRegions, regionId, isStateRequired } = this.state;

        if (!regionDisplayAll && !isStateRequired) {
            return null;
        }

        if (!availableRegions || !availableRegions.length) {
            return {
                region_string: {
                    label: __('State / Province'),
                    value: region,
                    validation: isStateRequired ? ['notEmpty'] : []
                }
            };
        }

        return {
            region_id: {
                label: __('State / Province'),
                type: 'select',
                selectOptions: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
                onChange: (regionId) => this.setState({ regionId }),
                value: regionId,
                validation: isStateRequired ? ['notEmpty'] : []
            }
        };
    }

    getStreetFields(label, placeholder, index) {
        const { address: { street = [] }, isSubmitted } = this.props;

        return {
            label,
            placeholder,
            value: street[index],
            validation: index === 0 ? ['notEmpty'] : [],
            validateSeparately: false,
            isSubmitted
        };
    }

    // returns the address fields in quantity equal to BE
    getAddressFields() {
        const { addressLinesQty } = this.props;

        if (addressLinesQty === 1) {
            return {
                street: this.getStreetFields(
                    __('Address'),
                    __('Your street address'),
                    0
                )
            };
        }

        const streets = {};

        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let i = 0; i < addressLinesQty; i++) {
            streets[`street${i}`] = this.getStreetFields(
                __('Street address line %s', i + 1),
                __('Your street address line %s', i + 1),
                i
            );
        }

        return streets;
    }

    get fieldMap() {
        const { countryId, city } = this.state;
        const { countries: sourceCountries } = this.props;
        // const { default_billing, default_shipping } = address;

        /*
        * Map and push empty field to show in case
        * if no country selected instead of default for myaccount
        */
        const countries = sourceCountries.map(({ id, label }) => ({ id, label, value: id }));
        countries.push({ id: ' ', label: ' ', value: ' ' });

        return {
            firstname: {
                label: __('First name'),
                validation: ['notEmpty'],
                validateSeparately: false,
                placeholder: __('Your first name')
            },
            lastname: {
                label: __('Last name'),
                validation: ['notEmpty'],
                validateSeparately: false,
                placeholder: __('Your last name')
            },
            ...this.getAddressFields(),
            city: {
                label: __('City'),
                validation: ['notEmpty'],
                validateSeparately: false,
                value: city,
                placeholder: __('Your city')
            },
            country_id: {
                type: 'select',
                label: __('Country'),
                validation: ['notEmpty'],
                validateSeparately: false,
                value: countryId,
                selectOptions: countries
            },
            ...this.getRegionFields(),
            postcode: {
                label: __('Zip / Postal code'),
                validation: ['notEmpty'],
                validateSeparately: false,
                placeholder: __('Your zip / postal code')
            },
            telephone: {
                label: __('Phone number'),
                validation: ['notEmpty', 'telephone'],
                validateSeparately: false
            }
        };
    }

    getDefaultValues(fieldEntry) {
        const [key, { value }] = fieldEntry;
        const { address: { [key]: addressValue } } = this.props;

        return {
            ...super.getDefaultValues(fieldEntry),
            value: value !== undefined ? value : addressValue
        };
    }

    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'MyAccount', elem: 'Button' } }
              mods={ { isHollow: true } }
            >
                { __('Save address') }
            </button>
        );
    }
}

export default MyAccountNewReturnAddressFormComponent;
