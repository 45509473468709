/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';

import ForgotPasswordComponent from './ForgotPassword.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/ForgotPassword/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/ForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    forgotPassword: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.forgotPassword(options, dispatch)
    )
});

/** @namespace Scandipwa/Component/ForgotPassword/Container/ForgotPasswordContainer */
export class ForgotPasswordContainer extends PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        forgotPassword: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    onForgotPasswordButtonClick() {
        const { email, showNotification } = this.props;

        const { forgotPassword } = this.props;

        forgotPassword({ email }).then(
            /** @namespace Scandipwa/Component/ForgotPassword/Container/forgotPassword/then */
            () => {
                showNotification('success', __('You will receive an email on your registered email id to reset your password.'));
            }
        );
    }

    render() {
        // const { email } = this.props;
        // console.log('email', email);
        return (
            <ForgotPasswordComponent onBtnClick={ this.onForgotPasswordButtonClick.bind(this) } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
