/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import Form from 'Component/Form';
import TimerButton from 'Component/TimerButton';
import {
    MyAccountSignIn as SourceMyAccountSignIn
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import {
    RESEND_OTP, SIGNIN, SIGNIN_SEND_OTP, SIGNIN_VERIFY_OTP
} from './MyAccountSignIn.config';

import './MyAccountSignIn.override.style';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component/MyAccountSignInComponent */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
  static propTypes = {
      onSignInWithOtpSuccess: PropTypes.func.isRequired,
      onSignInAttempt: PropTypes.func.isRequired,
      getLoginSuccessOTPQuery: PropTypes.func.isRequired,
      handleSignInWithOtp: PropTypes.func.isRequired,
      currentRenderForm: PropTypes.func.isRequired,
      handleRenderForm: PropTypes.func.isRequired,
      handleResendOTP: PropTypes.func.isRequired,
      handleBackToPrevious: PropTypes.func.isRequired,
      prepone: PropTypes.string,
      formFields: PropTypes.func.isRequired
  };

  static defaultProps = {
      prepone: ''
  };

    renderMap = {
        [SIGNIN]: this.renderSignInForm.bind(this),
        [SIGNIN_SEND_OTP]: this.renderSignInSendOTPForm.bind(this),
        [SIGNIN_VERIFY_OTP]: this.renderSignInVerifyOTPForm.bind(this),
        [RESEND_OTP]: this.renderResend.bind(this)
    };

    renderForm() {
        const { currentRenderForm } = this.props;

        return this.renderMap[currentRenderForm]();
    }

    renderResend() {
        const {
            onSignInAttempt, handleSignInVerifyOTPSuccess, onFormError, isCheckout, formFields, handleBackToPrevious
        } = this.props;

        return (
    <Form
      key="sign-in-verify"
      onSubmit={ onSignInAttempt }
      onSubmitSuccess={ handleSignInVerifyOTPSuccess }
      onSubmitError={ onFormError }
    >
      <Field
        type="mobileNumber"
        label={ __('Mobile Number') }
        id="mobileNumber"
        name="mobileNumber"
        value={ formFields.mobileNumber }
        prepone="+91"
        autocomplete={ isCheckout ? 'off' : 'mobileNumber' }
        validation={ ['notEmpty', 'mobileNumber'] }
      />
      <Field
        type="otp"
        label={ __('Enter OTP') }
        id="otp"
        name="otp"
        autocomplete={ isCheckout ? 'off' : 'otp' }
        validation={ ['notEmpty', 'otp'] }
      />
      <div block="MyAccountOverlay" elem="SignInWithOtpButton">
        <button block="Button" type="button" onClick={ handleBackToPrevious }>{ __('Retry') }</button>
      </div>
      <div block="MyAccountOverlay" elem="SignInWithOtpButton">
        <button block="Button" type="submit">{ __('submit') }</button>
      </div>
    </Form>
        );
    }

    renderSignInVerifyOTPForm() {
        const {
            onSignInAttempt, handleSignInVerifyOTPSuccess, onFormError, isCheckout, formFields, handleResendOTP
        } = this.props;

        return (
        <Form
          key="sign-in-verify"
          onSubmit={ onSignInAttempt }
          onSubmitSuccess={ handleSignInVerifyOTPSuccess }
          onSubmitError={ onFormError }
        >
          <Field
            type="mobileNumber"
            label={ __('Mobile Number') }
            id="mobileNumber"
            name="mobileNumber"
            value={ formFields.mobileNumber }
            prepone="+91"
            autocomplete={ isCheckout ? 'off' : 'mobileNumber' }
            validation={ ['notEmpty', 'mobileNumber'] }
          />
          <Field
            type="otp"
            label={ __('Enter OTP') }
            id="otp"
            name="otp"
            autocomplete={ isCheckout ? 'off' : 'otp' }
            validation={ ['notEmpty', 'otp'] }
          />
          <p className="emailSendRegistermsq">OTP sent to your mobile and email.</p>
          <div block="MyAccountOverlay" elem="SignInWithOtpButton">
            <TimerButton onClick={ handleResendOTP }>{ __('Resend Otp') }</TimerButton>
          </div>
          <div block="MyAccountOverlay" elem="SignInWithOtpButton">
            <button block="Button">{ __('submit') }</button>
          </div>
        </Form>
        );
    }

    renderSignInSendOTPForm() {
        const {
            handleSignInSendOTPSuccess,
            onSignInAttempt,
            onFormError,
            isCheckout
        } = this.props;

        return (
            <Form
              key="sign-in-otp"
              onSubmit={ onSignInAttempt }
              onSubmitSuccess={ handleSignInSendOTPSuccess }
              onSubmitError={ onFormError }
            >
                <Field
                  type="mobileNumber"
                  label={ __('Mobile Number') }
                  id="mobileNumber"
                  name="mobileNumber"
                  prepone="+91"
                  autocomplete={ isCheckout ? 'off' : 'mobileNumber' }
                  validation={ ['notEmpty', 'mobileNumber'] }
                />
                <div block="MyAccountOverlay" elem="SignInWithOtpButton">
                    <button block="Button">{ __('Send Otp') }</button>
                </div>
            </Form>
        );
    }

    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            isCheckout
        } = this.props;

        return (
          <Form
            key="sign-in"
            onSubmit={ onSignInAttempt }
            onSubmitSuccess={ onSignInSuccess }
            onSubmitError={ onFormError }
          >
              <Field
                type="email"
                label={ __('Email Address') }
                id="email"
                name="email"
                value={ emailValue }
                autocomplete={ isCheckout ? 'off' : 'email' }
                validation={ ['notEmpty', 'email'] }
                onChange={ handleEmailInput }
              />
              <Field
                type="password"
                label={ __('Password') }
                id="password"
                name="password"
                autocomplete="current-password"
                validation={ ['notEmpty', 'password'] }
              />
              <button
                type="button"
                block="Button"
                mods={ { likeLink: true } }
                mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                onClick={ handleForgotPassword }
              >
                  { __('Forgot password?') }
              </button>
              <div block="MyAccountOverlay" elem="SignInButton">
                  <button block="Button">{ __('Sign in') }</button>
              </div>
          </Form>
        );
    }

    renderAdditionalField() {
        const {
            isCheckout,
            handleCreateAccount,
            state
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
          <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
              <section>
                  <h4 id="forgot-password-label">{ __('New User?') }</h4>
                  <button
                    block="Button"
                    mods={ { likeLink: true } }
                    onClick={ handleCreateAccount }
                  >
                      { __('Create an account') }
                  </button>
              </section>
          </article>
        );
    }

    render() {
        const { handleRenderForm, currentRenderForm } = this.props;

        return (
            <>
            <div block="MyAccountSignIn">
              <div
                block="MyAccountSignIn"
                elem="Email"
                mods={ { isActive: currentRenderForm === SIGNIN } }
                onClick={ () => handleRenderForm(SIGNIN) }
              >
                Sign In With Password
              </div>
              <div
                block="MyAccountSignIn"
                elem="Otp"
                mods={ { isActive: (currentRenderForm === SIGNIN_SEND_OTP || currentRenderForm === SIGNIN_VERIFY_OTP || currentRenderForm === RESEND_OTP) } }
                onClick={ () => handleRenderForm(SIGNIN_SEND_OTP) }
              >
               Sign In With OTP
              </div>
            </div>
            <div className="SignInForm">
                { this.renderForm() }
                { this.renderAdditionalField() }
            </div>
            </>
        );
    }
}

export default MyAccountSignInComponent;
