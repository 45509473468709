import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { getMobileWithISD } from 'Util/Helper';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    getUpdateCustomerEmail(options) {
        const { email, password } = options;

        const mutation = new Field('updateCustomerEmail')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addField(
                new Field('customer').addFieldList([
                    'email'
                ])
            );

        return mutation;
    }

    getupdateMobSendOtp(mobileNumber) {
        const mutation = new Field('updateMobSendOtp')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addFieldList([
                'status',
                'message'
            ]);

        return mutation;
    }

    getupdateMobVerifyOtpAndUpdate(mobileNumber, otp) {
        const mutation = new Field('updateMobVerifyOtpAndUpdate')
            .addArgument('mobileNumber', 'String!', getMobileWithISD(mobileNumber))
            .addArgument('otp', 'String!', otp)
            .addFieldList([
                'status',
                'message'
            ]);

        return mutation;
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'gender',
            'mobilenumber',
            this._getAddressesField()
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'region_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField()
        ];
    }
}

export default new MyAccountQuery();
