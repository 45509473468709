import Loader from 'Component/Loader';
import {
    MyAccountAddressTable as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.override.style';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Component/MyAccountAddressTableComponent */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <button
                  block="Button"
                  onClick={ onEditClick }
                  mods={ { isHollow: true, isWithoutBorder: true } }
                >
                    { __('Edit') }
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true, isWithoutBorder: true } }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    { __('Remove') }
                </button>
            </>
        );
    }

    renderAddressTable() {
        const { address } = this.props;
        return (
            <div>
                <h4>{ __('Address') }</h4>
                <span>
                    { `${address.firstname} ${address.lastname}` }
                </span>
                <span>
                    { `${address.street}` }
                </span>
                <span>
                    { __('Pincode:') }
                    { ' ' }
                    { address.postcode }
                </span>
                <span>
                    { __('Mobile:') }
                    { ` ${address.telephone}` }
                </span>
            </div>
        );
    }

    render() {
        const { countries, mix } = this.props;
        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                { this.renderAddressTable() }
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountAddressTableComponent;
