import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import MyAccountNewReturnAddressForm from '../MyAccountNewReturnAddressForm';
import { MY_ACCOUNT_NEW_RETURN_ADDRESS_POPUP } from './MyAccountNewReturnAddressPopup.config';

import './MyAccountNewReturnAddressPopup.style';

/** @namespace Rma/Component/MyAccountNewReturnAddressPopup/Component/MyAccountNewReturnAddressPopupComponent */
export class MyAccountNewReturnAddressPopupComponent extends PureComponent {
    static propTypes = {
        address: PropTypes.objectOf().isRequired,
        updateReturnAddress: PropTypes.func.isRequired
    };

    render() {
        const { address, updateReturnAddress } = this.props;

        return (
            <Popup
              id={ MY_ACCOUNT_NEW_RETURN_ADDRESS_POPUP }
              mix={ { block: 'MyAccountNewReturnAddressPopup' } }
            >
                <div block="MyAccountNewReturnAddressPopup">
                    <MyAccountNewReturnAddressForm
                      address={ address }
                      onSave={ updateReturnAddress }
                    />
                </div>
            </Popup>
        );
    }
}

export default MyAccountNewReturnAddressPopupComponent;
