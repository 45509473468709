import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Route/SomethingWentWrong/Container/SomethingWentWrongContainer */
export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    componentDidMount() {
        const { updateMeta, errorDetails: { err: { name } } } = this.props;

        // reload the page if its a chunk error
        if (name === 'ChunkLoadError') {
            this.refreshPage();
        }

        updateMeta({ title: __('Something went wrong!') });
    }

    refreshPage() {
        navigator.serviceWorker.getRegistrations().then(
            /** @namespace Scandipwa/Route/SomethingWentWrong/Container/getRegistrations/then */
            (registrations) => {
                registrations.forEach((registration) => {
                    registration.unregister();
                });
            }
        );

        caches.keys().then(
            /** @namespace Scandipwa/Route/SomethingWentWrong/Container/keys/then */
            (keyList) => Promise.all(
                keyList.map((key) => caches.delete(key))
            )
        );

        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem('pageHasBeenForceRefreshed') || 'false'
        );

        if (!pageHasAlreadyBeenForceRefreshed) {
            window.localStorage.setItem('pageHasBeenForceRefreshed', 'true');
            window.location.reload(true);
        } else {
            window.localStorage.setItem('pageHasBeenForceRefreshed', 'false');
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
