import SearchItem from 'Component/SearchItem';
import {
    AMOUNT_OF_PLACEHOLDERS
} from 'Component/SearchOverlay/SearchOverlay.config';
import { updateLoadStatus, updateSearchBar } from 'Store/SearchBar/SearchBar.action';
import { SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import { EVENT_GTM_IMPRESSIONS_SEARCH } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const state = (original) => ({
    ...original,
    lastSearch: '',
    isFired: false
});

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const { searchCriteria: prevSearchCriteria } = prevProps;
    const {
        searchCriteria, isLoading, updateLoadStatus
    } = instance.props;

    if (searchCriteria !== prevSearchCriteria) {
        updateLoadStatus(true);
        instance.setState({ isFired: false });
    }

    callback(...args);

    if (searchCriteria === prevSearchCriteria && !instance.timeout && !isLoading) {
        instance.setState({ lastSearch: searchCriteria });
    }
};

const renderSearchItem = (args, callback, instance) => {
    const [product, i] = args;

    return (
            <SearchItem
              product={ product }
              key={ i }
              position={ i + 1 }
            />
    );
};

const renderSearchResults = (args, callback, instance) => {
    const {
        searchResults, isLoading, event, searchCriteria
    } = instance.props;
    const { lastSearch, isFired } = instance.state;

    const resultsToRender = (isLoading || instance.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

    if (!isLoading && !instance.timeout && (lastSearch === searchCriteria) && !isFired) {
        event(EVENT_GTM_IMPRESSIONS_SEARCH, {
            products: resultsToRender, search: searchCriteria, list: SEARCH
        });
        instance.setState({ isFired: true });
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        updateLoadStatus: (status) => dispatch(updateLoadStatus(status))
    };
};

const onSuccess = (args) => {
    const [data, dispatch] = args;

    dispatch(updateSearchBar(data));
    dispatch(updateLoadStatus(false));
};


const containerProps = (args, callback, instance) => {
    const {
        event,
        updateLoadStatus
    } = instance.props;

    return {
        ...callback(...args),
        event,
        updateLoadStatus
    };
}


export default {
    'Component/SearchOverlay/Component': {
        'member-function': {
            componentDidUpdate,
            renderSearchItem,
            renderSearchResults
        },
        'member-property': {
            state
        }
    },
    'Component/SearchOverlay/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/SearchOverlay/Container': {
        'member-function': {
            containerProps
        }
    },
    'Store/SearchBar/Dispatcher': {
        'member-function': {
            onSuccess
        }
    }
};
