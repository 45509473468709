/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

import Field from './Field.component';
import {
    CHECKBOX_TYPE,
    DATE_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

/** @namespace Scandipwa/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...this.propTypes,
        prepone: PropTypes.string,
        type: PropTypes.oneOf([
            TEXT_TYPE,
            NUMBER_TYPE,
            TEXTAREA_TYPE,
            PASSWORD_TYPE,
            RADIO_TYPE,
            CHECKBOX_TYPE,
            SELECT_TYPE,
            FILE_TYPE,
            EMAIL_TYPE,
            DATE_TYPE
        ]).isRequired
    };

    containerFunctions = {
        ...this.containerFunctions
    };

    containerProps() {
        const { prepone, type } = this.props;
        const { checked } = this.state;

        return {
            ...super.containerProps(),
            checked: type === CHECKBOX_TYPE ? checked : checked,
            prepone
        };
    }

    render() {
        return (
            <Field
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
