/* eslint-disable max-len */
import {
    ListIcon as SourceListIcon
} from 'SourceComponent/ListIcon/ListIcon.component';

import './ListIcon.override.style';

/** @namespace Scandipwa/Component/ListIcon/Component/ListIconComponent */
export class ListIconComponent extends SourceListIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="ListIcon"
              mods={ { isActive } }
              width="19"
              height="19"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path fillRule="evenodd" clipRule="evenodd" d="M16 12V14H0V12H16ZM16 6V8H0V6H16ZM16 0V2H0V0H16Z" fill="#0B1F3F" />
            </svg>

        );
    }
}

export default ListIconComponent;
