import { format } from 'date-fns';

/** @namespace Scandipwa/Util/DateTime/Index/toDateString */
export const toDateString = (rawDate = '', locale = true) => {
    if (locale) {
        return new Date(rawDate).toLocaleDateString();
    }

    return new Date(rawDate).toDateString();
};

/** @namespace Scandipwa/Util/DateTime/Index/toDateTimeString */
export const toDateTimeString = (rawDate = '', locale = true) => {
    if (locale) {
        return new Date(rawDate).toLocaleString();
    }

    return new Date(rawDate).toString();
};

/** @namespace Scandipwa/Util/DateTime/Index/renderFormattedDate */
export const renderFormattedDate = (dateString, formatTo = 'do MMM, yyyy') => {
    const date = new Date(dateString);
    return format(date, formatTo);
};
