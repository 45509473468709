import { PropTypes } from 'prop-types';

import {
    FieldInputContainer as SourceFieldInputContainer
} from 'SourceComponent/FieldInput/FieldInput.container';

import FieldInput from './FieldInput.component';

/** @namespace Scandipwa/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends SourceFieldInputContainer {
    static propTypes = {
        ...this.propTypes,
        prepone: PropTypes.string
    };

    containerProps() {
        const { prepone } = this.props;

        return {
            ...super.containerProps(),
            prepone
        };
    }

    render() {
        return (
            <FieldInput { ...this.containerProps() } />
        );
    }
}

export default FieldInputContainer;
