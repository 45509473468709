import {
    MyAccountForgotPasswordSuccess as SourceMyAccountForgotPasswordSuccess
} from 'SourceComponent/MyAccountForgotPasswordSuccess/MyAccountForgotPasswordSuccess.component';

/** @namespace Scandipwa/Component/MyAccountForgotPasswordSuccess/Component/MyAccountForgotPasswordSuccessComponent */
export class MyAccountForgotPasswordSuccessComponent extends SourceMyAccountForgotPasswordSuccess {
    // TODO implement logic
    render() {
        const { state, handleSignIn } = this.props;

        return (
            <article
              aria-labelledby="forgot-password-success"
              block="MyAccountOverlay"
              elem="Additional"
              mods={ { state } }
            >
                <p id="forgot-password-success">
                    { /* eslint-disable-next-line max-len */ }
                    { __('You will receive an email on your registered email id to reset your password.') }
                </p>
                <button
                  block="Button"
                  onClick={ handleSignIn }
                >
                    { __('Got it') }
                </button>
            </article>
        );
    }
}

export default MyAccountForgotPasswordSuccessComponent;
