import {
    formatOrders,
    getFormattedDate,
    getInitialState,
    orderList,
    OrderReducer as sourceOrderReducer,
    ORDERS
} from 'SourceStore/Order/Order.reducer';

import { SET_ORDER_LOADING_STATUS } from './Order.action';

export {
    ORDERS,
    getFormattedDate,
    formatOrders,
    orderList,
    getInitialState
};

// TODO: implement OrderReducer
/** @namespace Scandipwa/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (state = getInitialState(), action) => {
    const {
        type,
        status
    } = action;

    switch (type) {
    case SET_ORDER_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    default:
        return sourceOrderReducer(state, action);
    }
};

export default OrderReducer;
