export const SET_CURRENT_ZIPCODE = 'SET_CURRENT_ZIPCODE';
export const SET_ZIPCODE_PRODUCT_SEVICEABILITY = 'SET_ZIPCODE_PRODUCT_SEVICEABILITY';

/** @namespace Scandipwa/Store/ZipCodeServiceability/Action/setCurrentZipCode */
export const setCurrentZipCode = (zipCode) => ({
    type: SET_CURRENT_ZIPCODE,
    zipCode
});

/** @namespace Scandipwa/Store/ZipCodeServiceability/Action/setZipCodeProductServiceability */
export const setZipCodeProductServiceability = (zipCodeProductServiceabilityData) => ({
    type: SET_ZIPCODE_PRODUCT_SEVICEABILITY,
    zipCodeProductServiceabilityData
});
