/* eslint-disable max-len */
import Link from 'Component/Link';
import { PERSONAL_INFO } from 'Route/MyAccount/MyAccount.component';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import {
    MyAccountCustomerTable as SourceMyAccountCustomerTable
} from 'SourceComponent/MyAccountCustomerTable/MyAccountCustomerTable.component';

import ForgotPassword from '../ForgotPassword';

import './MyAccountCustomerTable.override.style';

/** @namespace Scandipwa/Component/MyAccountCustomerTable/Component/MyAccountCustomerTableComponent */
export class MyAccountCustomerTableComponent extends SourceMyAccountCustomerTable {
    render() {
        const {
            customer: {
                firstname, lastname, email, mobilenumber
            }
        } = this.props;
        const { showChangePasswordPopup } = this.props;

        if (!firstname && !lastname) {
            return '';
        }

        return (
            <div block="MyAccountCustomerTable" elem="CustomerData">
                <h4>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="14.5" stroke="#0B1F3F" />
<path d="M15 15.4C17.21 15.4 19 13.52 19 11.2C19 8.88 17.21 7 15 7C12.79 7 11 8.88 11 11.2C11 13.52 12.79 15.4 15 15.4ZM17.8 16.45H17.278C16.584 16.785 15.813 16.975 15 16.975C14.187 16.975 13.419 16.785 12.722 16.45H12.2C9.881 16.45 8 18.425 8 20.86V22.225C8 23.095 8.672 23.8 9.5 23.8H20.5C21.328 23.8 22 23.095 22 22.225V20.86C22 18.425 20.119 16.45 17.8 16.45Z" fill="#0B1F3F" />
                </svg>
                { __('Personal Info') }
                    <Link to={ `${MY_ACCOUNT_URL}/${PERSONAL_INFO}` }>{ __('View All') }</Link>
                </h4>
                <ul block="MyAccountDashboard" elem="CustomerDetails">
                    <li className="Heading">Contact Information</li>
                    <li>
                        { `${ firstname } ${ lastname } ` }
                    </li>
                    <li>{ email }</li>
                    <li>{ mobilenumber }</li>
                    <li>
                        <button
                          block="Button Button_isHollow Button_isWithoutBorder"
                          mods={ { isHollow: true, isWithoutBorder: true } }
                          onClick={ showChangePasswordPopup }
                        >
                        { __('Change password') }
                        </button>
                    </li>
                    <li block="ForgotPassword">
                        <ForgotPassword email={ email } />
                    </li>
                </ul>
            </div>
        );
    }
}

export default MyAccountCustomerTableComponent;
