import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import OtpQuery from '../../query/Otp.query';
import MyAccountSignIn from './MyAccountSignIn.component';
import {
    RESEND_OTP, SIGNIN_SEND_OTP, SIGNIN_VERIFY_OTP
} from './MyAccountSignIn.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    signInVerify: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.signInVerify(options, dispatch)
    )
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/MyAccountSignInContainer */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...this.propTypes,
        handleSignInWithOtp: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        prepone: PropTypes.string
    };

    state = {
        currentRenderForm: SIGNIN_SEND_OTP,
        formFields: {},
        count: 0,
        resendCount: 0
    };

    handleRenderForm(key) {
        this.setState({
            currentRenderForm: key
        });
    }

    containerFunctions = {
        handleRenderForm: this.handleRenderForm.bind(this),
        onSignInSuccess: this.onSignInSuccess.bind(this),
        onSignInAttempt: this.onSignInAttempt.bind(this),
        handleSignInSendOTPSuccess: this.handleSignInSendOTPSuccess.bind(this),
        handleSignInVerifyOTPSuccess: this.handleSignInVerifyOTPSuccess.bind(this),
        handleResendOTP: this.handleResendOTP.bind(this),
        handleBackToPrevious: this.handleBackToPrevious.bind(this)
    };

    containerProps= () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            prepone
        } = this.props;

        const { currentRenderForm, formFields } = this.state;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            currentRenderForm,
            formFields,
            prepone
        };
    };

    async handleSignInVerifyOTPSuccess(fields) {
        const {
            signInVerify,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        try {
            await signInVerify(fields);
            onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));

            this.setState((prevState) => ({
                count: prevState.count + 1
            }), () => {
                const { count } = this.state;
                // eslint-disable-next-line no-magic-numbers
                if (count >= 3) {
                    this.setState({
                        currentRenderForm: SIGNIN_SEND_OTP,
                        formFields: {},
                        count: 0
                    });
                }
            });
        }

        setLoadingState(false);
    }

    async handleSignInSendOTPSuccess(fields) {
        const {
            showNotification,
            setLoadingState
        } = this.props;

        try {
            const mutation = OtpQuery.getLoginOTPQuery(fields.mobileNumber);
            await fetchMutation(mutation);

            this.setState({
                currentRenderForm: SIGNIN_VERIFY_OTP,
                formFields: fields
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    async handleResendOTP() {
        const {
            showNotification,
            setLoadingState
        } = this.props;

        const fields = this.state.formFields;

        try {
            const mutation = OtpQuery.getLoginOTPQuery(fields.mobileNumber);
            await fetchMutation(mutation);

            this.setState({ count: 0 });

            this.setState((prevState) => ({
                resendCount: prevState.resendCount + 1
            }), () => {
                const { resendCount } = this.state;
                // eslint-disable-next-line no-magic-numbers
                if (resendCount >= 3) {
                    this.setState({
                        currentRenderForm: RESEND_OTP,
                        formFields: {},
                        resendCount: 0
                    });
                }
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
    }

    handleBackToPrevious(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        this.setState({
            currentRenderForm: SIGNIN_SEND_OTP,
            formFields: {},
            resendCount: 0,
            count: 0
        });
    }

    render() {
        return (
            <MyAccountSignIn
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
