// TODO update this import when renamed
import { PRODUCT_SEVICEABILITY } from './ProductServiceAvailable.action';

/** @namespace Scandipwa/Store/ProductServiceAvailable/Reducer/getInitialState */
export const getInitialState = () => ({
    // TODO set initial state
});

/** @namespace Scandipwa/Store/ProductServiceAvailable/Reducer/ProductServiceAvailableReducer */
export const ProductServiceAvailableReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case PRODUCT_SEVICEABILITY:
        return {
            ...state,
            ...action
        };

    default:
        return state;
    }
};

export default ProductServiceAvailableReducer;
