/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_STORE_FINDER
} from './StoreFinder.action';

export const initialState = {
    storeListCities: [],
    statesList: [],
    stateCitiesMapped: {},
    storeListMapped: {},
    storeByName: {},
    storeById: {},
    storeList: [],
    isLoading: true
};

/** @namespace Storefinder/Store/StoreFinder/Reducer/StoreFinderReducer */
export const StoreFinderReducer = (state = initialState, action) => {
    const { storeList, type } = action;
    switch (type) {
    case UPDATE_STORE_FINDER:
        const storeListObject = storeList.reduce((data, store) => {
            const {
                city, state, longitude, latitude, store_name, identifier
            } = store;

            if (city && state && longitude && latitude) {
                // cities list
                if (!data.storeListCities.includes(city)) {
                    data.storeListCities.push(city);
                }
                // states list
                if (!data.statesList.includes(state)) {
                    data.statesList.push(state);
                }
                // city - stores mapping
                if (!data.storeListMapped[city]) {
                    // eslint-disable-next-line no-param-reassign
                    data.storeListMapped[city] = [];
                }

                // state - cities mapping
                if (!data.stateCitiesMapped[state]) {
                    // eslint-disable-next-line no-param-reassign
                    data.stateCitiesMapped[state] = [];
                }

                data.storeListMapped[city].push(store);

                if (!data.stateCitiesMapped[state].includes(city)) {
                    data.stateCitiesMapped[state].push(city);
                }

                // eslint-disable-next-line no-param-reassign
                data.storeByName[store_name] = store;

                // eslint-disable-next-line no-param-reassign
                data.storeById[identifier] = store;
            }

            return data;
        }, {
            storeListCities: [],
            statesList: [],
            stateCitiesMapped: {},
            storeListMapped: {},
            storeByName: {},
            storeById: {}
        });

        return {
            ...state,
            ...storeListObject,
            storeList
        };

    default:
        return state;
    }
};

export default StoreFinderReducer;
