/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
import { lazy, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import MyAccountTabList from 'Component/MyAccountTabList';
import MyAccountViewOrder from 'Component/MyAccountViewOrder';
import {
    MyAccount as SourceMyAccount,
    MyAccountAddressBook as SourceMyAccountAddressBook,
    MyAccountDashboard as SourceMyAccountDashboard,
    MyAccountDownloadable as SourceMyAccountDownloadable,
    MyAccountMyOrders as SourceMyAccountMyOrders,
    MyAccountMyWishlist as SourceMyAccountMyWishlist,
    MyAccountNewsletterSubscription as SourceMyAccountNewsletterSubscription
} from 'SourceRoute/MyAccount/MyAccount.component';
import {
    ADDRESS_BOOK,
    DASHBOARD,
    MY_DOWNLOADABLE,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION
} from 'Type/Account';
import { isSignedIn } from 'Util/Auth';

import { ACCOUNT_ORDER_URL } from './MyAccount.config';

import './MyAccount.override.style';

// TODO: implement MyAccountAddressBook
export const MyAccountAddressBook = SourceMyAccountAddressBook;

// TODO: implement MyAccountDashboard
export const MyAccountDashboard = SourceMyAccountDashboard;

// TODO: implement MyAccountDownloadable
export const MyAccountDownloadable = SourceMyAccountDownloadable;

// TODO: implement MyAccountMyOrders
export const MyAccountMyOrders = SourceMyAccountMyOrders;

// TODO: implement MyAccountMyWishlist
export const MyAccountMyWishlist = SourceMyAccountMyWishlist;

// TODO: implement MyAccountNewsletterSubscription
export const MyAccountNewsletterSubscription = SourceMyAccountNewsletterSubscription;

export const MyAccountPersonalInfo = lazy(() => import(
    'Component/MyAccountPersonalInfo'
));

export const MyAccountStoreCredit = lazy(() => import(
    'Component/MyAccountStoreCredit'
));

export const PERSONAL_INFO = 'personal-info';

export const STORE_CREDIT = 'store-credit';

export const MY_ORDER = 'view-order';

/** @namespace Scandipwa/Route/MyAccount/Component/MyAccountComponent */
export class MyAccountComponent extends SourceMyAccount {
    renderMap = {
        [DASHBOARD]: MyAccountDashboard,
        [PERSONAL_INFO]: MyAccountPersonalInfo,
        [MY_ORDER]: MyAccountViewOrder,
        [MY_ORDERS]: MyAccountMyOrders,
        [MY_WISHLIST]: MyAccountMyWishlist,
        [STORE_CREDIT]: MyAccountStoreCredit,
        [ADDRESS_BOOK]: MyAccountAddressBook,
        [NEWSLETTER_SUBSCRIPTION]: MyAccountNewsletterSubscription,
        [MY_DOWNLOADABLE]: MyAccountDownloadable
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    shouldComponentUpdate(nextProps) {
        const { activeTab, location: { pathname } } = this.props;
        const { activeTab: nextActiveTab, location: { pathname: nextPathname } } = nextProps;

        return activeTab !== nextActiveTab || pathname !== nextPathname;
    }

    getTabContent = () => {
        const { activeTab, location: { pathname } } = this.props;
        if (activeTab === MY_ORDERS && pathname.includes(ACCOUNT_ORDER_URL)) {
            return this.renderMap[MY_ORDER];
        }

        return this.renderMap[activeTab];
    };

    getTabMap = () => {
        const {
            activeTab, location: { pathname }, tabMap
        } = this.props;

        if (activeTab === MY_ORDERS && pathname.includes(ACCOUNT_ORDER_URL)) {
            return null;
        }

        const { name, icon } = tabMap[activeTab];

        return (
            <h2
              block="MyAccount"
              elem="Heading"
              mods={ { isHidden: activeTab === DASHBOARD } }
            >
            { icon }
            { ' ' }
            { name }
            </h2>
        );
    };

    renderContent() {
        const {
            activeTab,
            tabMap,
            changeActiveTab,
            onSignOut,
            isEditingActive,
            match,
            location
        } = this.props;

        if (!isSignedIn()) {
            return this.renderLoginOverlay();
        }

        const TabContent = this.getTabContent();
        // const { name, icon } = tabMap[activeTab];

        return (
            <ContentWrapper
              label={ __('My Account page') }
              wrapperMix={ { block: 'MyAccount', elem: 'Wrapper' } }
            >
                <MyAccountTabList
                  tabMap={ tabMap }
                  activeTab={ activeTab }
                  changeActiveTab={ changeActiveTab }
                  onSignOut={ onSignOut }
                />
                <div
                  block="MyAccount"
                  elem="TabContent"
                  mods={ { isHidden: activeTab === DASHBOARD, isHide: activeTab === MY_ORDER } }
                >
                         { this.getTabMap() }
                    <Suspense fallback={ <Loader /> }>
                        <TabContent isEditingActive={ isEditingActive } match={ match } location={ location } />
                    </Suspense>
                </div>
            </ContentWrapper>
        );
    }

    render() {
        return (
            <main block="MyAccount">
                { this.renderContent() }
            </main>
        );
    }
}

export default MyAccountComponent;
