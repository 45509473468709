import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MyAccountOrderTab.style';

/** @namespace Scandipwa/Component/MyAccountOrderTab/Component/MyAccountOrderTabComponent */
export class MyAccountOrderTabComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        handleClickOnTab: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired
    };

    render() {
        const { title, isActive, handleClickOnTab } = this.props;

        return (
            <li
              block="MyAccountOrderTab"
              elem="Item"
              mods={ { isActive } }
            >
                <button
                  mix={ { block: 'MyAccountOrderTab', elem: 'Button' } }
                  onClick={ handleClickOnTab }
                >
                    { title }
                </button>
            </li>
        );
    }
}

export default MyAccountOrderTabComponent;
