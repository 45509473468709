import {
    Link as SourceLink
} from 'SourceComponent/Link/Link.component';

import './Link.override.style';

/** @namespace Scandipwa/Component/Link/Component/LinkComponent */
export class LinkComponent extends SourceLink {
    // TODO implement logic
}

export default LinkComponent;
