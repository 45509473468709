import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ForgotPassword.style';

/** @namespace Scandipwa/Component/ForgotPassword/Component/ForgotPasswordComponent */
export class ForgotPasswordComponent extends PureComponent {
    static propTypes = {
        onBtnClick: PropTypes.func.isRequired
    };

    render() {
        const { onBtnClick } = this.props;
        return (

                <button
                  onClick={ onBtnClick }
                >
                        { __('Forgot Password') }
                </button>

        );
    }
}

export default ForgotPasswordComponent;
