import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Link/Link.container';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { appendWithStoreCode } from 'Util/Url';

import Link from './Link.component';

export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

/** @namespace Scandipwa/Component/Link/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Scandipwa/Component/Link/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateNoMatch: (options) => dispatch(updateNoMatch(options))
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Component/Link/Container/LinkContainer */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        baseLinkUrl: PropTypes.string.isRequired,
        updateNoMatch: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        to: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]).isRequired
    };

    static defaultProps = {
        onClick: () => {}
    };

    containerFunctions = {
        onClick: this.onClick.bind(this)
    };

    containerProps = () => {
        const {
            block,
            elem,
            mods,
            mix,
            // baseLinkUrl, // remove this prop
            // dispatch, // remove this prop
            ...restProps
        } = this.props;

        return {
            ...restProps,
            to: this.getTo(),
            bemProps: {
                block,
                elem,
                mods,
                mix
            }
        };
    };

    getTo() {
        const { to: toProp } = this.props;
        // fix null, undefined and empty links
        const to = toProp || '/';

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^https?:\/\//.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const pathname = to.pathname || '/';

        return {
            ...to,
            pathname: appendWithStoreCode(pathname)
        };
    }

    // Resets no match state on redirect
    onClick(e) {
        const { updateNoMatch, onClick } = this.props;
        updateNoMatch(false);

        if (onClick) {
            onClick(e);
        }
    }

    render() {
        return (
            <Link
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
