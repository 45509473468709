import {
    getInitialState as sourceGetInitialState,
    MyAccountReducer
} from 'SourceStore/MyAccount/MyAccount.reducer';

export {
    MyAccountReducer
};

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

export default MyAccountReducer;
