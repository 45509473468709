/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { customerType } from 'Type/Account';
import { fetchMutation, fetchQuery } from 'Util/Request';
import {
    getQueryParam
} from 'Util/Url';

import CustomerRmaQuery from '../../query/CustomerRma.query';
import MyAccountNewReturn from './MyAccountNewReturn.component';

/** @namespace Rma/Component/MyAccountNewReturn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Rma/Component/MyAccountNewReturn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message))
});

/** @namespace Rma/Component/MyAccountNewReturn/Container/MyAccountNewReturnContainer */
export class MyAccountNewReturnContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        showNotification: PropTypes.func.isRequired,
        showSuccessNotification: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        history: PropTypes.object.isRequired
    };

    state = {
        reasonData: {},
        isLoading: false,
        orderId: '',
        orderItemId: '',
        baseOrderInfo: {},
        items: [],
        createdAt: '',
        shippingAddress: {}
    };

    containerFunctions = {
        onNewRequestSubmit: this.onNewRequestSubmit.bind(this),
        updateReturnAddress: this.updateReturnAddress.bind(this)
    };

    componentDidMount() {
        this.requestData();
    }

    updateReturnAddress(rmaAddress) {
        const newAddress = {
            firstname: rmaAddress.firstname,
            lastname: rmaAddress.lastname,
            street: [rmaAddress.street],
            city: rmaAddress.city,
            country_id: rmaAddress.country_id,
            region_id: rmaAddress.region.region_id,
            region: rmaAddress.region.region,
            telephone: rmaAddress.telephone,
            postcode: rmaAddress.postcode
        };

        this.setState({
            shippingAddress: newAddress
        });
    }

    onError = (e) => {
        const { showNotification } = this.props;

        this.setState({ isLoading: false }, () => {
            showNotification('error', __('Error mutation New Return!'), e);
        });
    };

    async onNewRequestSubmit(options) {
        const { showSuccessNotification, history } = this.props;

        this.setState({ isLoading: true });

        const mutation = CustomerRmaQuery.createRmaMutation(this.formatReturnRequestOption(options));
        fetchMutation(mutation).then(
            /** @namespace Rma/Component/MyAccountNewReturn/Container/fetchMutation/then */
            ({ createRma: { rma } }) => {
                this.setState({ isLoading: false }, () => {
                    showSuccessNotification(__('Return successfully made, order ID: ') + rma.increment_id);
                });

                history.goBack();
            },
            this.onError
        );
    }

    formatReturnRequestOption(options) {
        const { baseOrderInfo } = this.state;
        const [order] = baseOrderInfo.filter((item) => item.item_id === options.order_item_id);
        const {
            id, store_id, order_type, bag_id
        } = order;

        return {
            rma_orders: [{
                // order_id: String(baseOrderInfo.id),
                // store_id: baseOrderInfo.store_id,
                // type: baseOrderInfo.order_type,
                order_id: String(id),
                store_id,
                type: order_type,
                items: options.items,
                bag_id
            }],
            rma_address: [{
                firstname: options.rmaAddress.firstname,
                lastname: options.rmaAddress.lastname,
                street: options.rmaAddress.street.toString(),
                city: options.rmaAddress.city,
                company: '',
                country_id: options.rmaAddress.country_id,
                region: options.rmaAddress.region,
                region_id: options.rmaAddress.region_id,
                telephone: options.rmaAddress.telephone,
                postcode: options.rmaAddress.postcode
            }],
            agree_box: true
        };
    }

    requestData() {
        const { showNotification, history: { location: { pathname }, location } } = this.props;
        const queryString = pathname.split('?')[1];

        const queryObj = new URLSearchParams(queryString);
        // const orderId = queryObj.get('id');
        // const orderItemId = queryObj.get('itemid');
        const orderId = getQueryParam('id', location) || queryObj.get('id');
        const orderItemId = getQueryParam('itemid', location) || queryObj.get('itemid');

        return fetchQuery([
            CustomerRmaQuery.getRmaDictionary(orderId),
            OrderQuery.getOrderByIdQuery(orderId)

        ]).then(
            /** @namespace Rma/Component/MyAccountNewReturn/Container/fetchQuery/then */
            ({
                rmaDictionary: {
                    reasons,
                    resolutions,
                    conditions
                },
                getOrderById: {
                    order_products: items,
                    base_order_info: baseOrderInfo,
                    base_order_info: { created_at: createdAt },
                    shipping_info: { shipping_address: shippingAddress }
                }
            }) => {
                const reasonData = { reasons, resolutions, conditions };

                if (!Array.isArray(shippingAddress.street)) {
                    // eslint-disable-next-line no-param-reassign
                    shippingAddress.street = [shippingAddress.street];
                }
                if (orderItemId) {
                    const newItems = items.filter((element) => element.order_item_id === orderItemId);
                    this.setState({
                        reasonData,
                        baseOrderInfo,
                        items: newItems,
                        orderId,
                        orderItemId,
                        createdAt,
                        shippingAddress
                    });
                } else {
                    this.setState({
                        reasonData,
                        baseOrderInfo,
                        items,
                        orderId,
                        orderItemId,
                        createdAt,
                        shippingAddress
                    });
                }
            },
            /** @namespace Rma/Component/MyAccountNewReturn/Container/fetchQuery/then */
            (e) => showNotification('error', __('Error fetching New Return!'), e)
        );
    }

    render() {
        return (
            <MyAccountNewReturn
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewReturnContainer);
