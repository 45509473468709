import {
    SHOW_VAT_NUMBER_OPTIONAL as SOURCE_SHOW_VAT_NUMBER_OPTIONAL,
    SHOW_VAT_NUMBER_REQUIRED as SOURCE_SHOW_VAT_NUMBER_REQUIRED
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

// TODO: implement SHOW_VAT_NUMBER_REQUIRED
export const SHOW_VAT_NUMBER_REQUIRED = SOURCE_SHOW_VAT_NUMBER_REQUIRED;

// TODO: implement SHOW_VAT_NUMBER_OPTIONAL
export const SHOW_VAT_NUMBER_OPTIONAL = SOURCE_SHOW_VAT_NUMBER_OPTIONAL;

export const CREATE_ACCOUNT = 'create-account';

export const CREATE_ACCOUNT_VERIFY_OTP = 'create-account-verify-otp';

export const CREATE_ACCOUNT_RETRY = 'create-account-retry';
