import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

import {
    STATE_LOGGED_IN,
    STATE_SIGN_IN
} from './MyAccountOverlay.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/MyAccountOverlay/Container/MyAccountOverlayContainer */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    // TODO implement logic
    static getDerivedStateFromProps(props, state) {
        const {
            isPasswordForgotSend,
            showNotification,
            isOverlayVisible,
            isMobile
        } = props;

        const {
            isPasswordForgotSend: currentIsPasswordForgotSend,
            state: myAccountState
        } = state;

        const { location: { pathname, state: { isForgotPassword } = {} } } = history;

        const stateToBeUpdated = {};
        const customerIsSignedIn = isSignedIn();

        if (!isMobile) {
            if (!isOverlayVisible && !customerIsSignedIn) {
                if (pathname !== '/forgot-password' && !isForgotPassword) {
                    stateToBeUpdated.state = STATE_SIGN_IN;
                }
            } else if (!isOverlayVisible && customerIsSignedIn) {
                stateToBeUpdated.state = STATE_LOGGED_IN;
            }
        }

        if (myAccountState !== STATE_LOGGED_IN && customerIsSignedIn) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.state = STATE_LOGGED_IN;
        }

        if (myAccountState === STATE_LOGGED_IN && !customerIsSignedIn) {
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        if (isPasswordForgotSend !== currentIsPasswordForgotSend) {
            stateToBeUpdated.isLoading = false;
            stateToBeUpdated.isPasswordForgotSend = isPasswordForgotSend;
            // eslint-disable-next-line max-len
            showNotification('success', __('You will receive an email on your registered email id to reset your password.'));
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        return Object.keys(stateToBeUpdated).length ? stateToBeUpdated : null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
