import {
    isSignedIn
} from 'Util/Auth';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
const handleCustomerDataOnInit = (args, callback) => {
    const [dispatch] = args;
    callback(...args);

    if (isSignedIn()) {
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
        );
    }
};

export const config = {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            handleCustomerDataOnInit
        }
    }
};

export default config;
