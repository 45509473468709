import {
    NotificationList as SourceNotificationList
} from 'SourceComponent/NotificationList/NotificationList.component';

import './NotificationList.override.style';

/** @namespace Scandipwa/Component/NotificationList/Component/NotificationListComponent */
export class NotificationListComponent extends SourceNotificationList {
    // TODO implement logic
}

export default NotificationListComponent;
