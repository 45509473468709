/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MIN_PASSWORD_LENGTH = 8;

/** @namespace Scandipwa/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Scandipwa/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Scandipwa/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Scandipwa/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^\+?(?:[0-9-] ?){6,14}[0-9]$/);

/** @namespace Scandipwa/Component/Form/Config/validatePhone */
export const validatePhone = ({ value }) => value.length > 0 && value.match(/^\d{10}$/);

/** @namespace Scandipwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Scandipwa/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };

    return value === passwordValue;
};

/** @namespace Scandipwa/Component/Form/Config/validateNumber */
export const validateNumber = ({ value }) => value.length > 0 && value.match(/^[0-9]$/);

/** @namespace Scandipwa/Component/Form/Config/validatePinCode */
export const validatePinCode = ({ value }) => value.length > 0 && value.match(/^\d{6}$/);

/** @namespace Scandipwa/Component/Form/Config/validateZipCode */
export const validateZipCode = ({ value }) => value.length > 0 && value.match(/^\d{6}$/);

/** @namespace Scandipwa/Component/Form/Config/validateFirstName */
export const validateFirstName = ({ value }) => value.length > 0 && value.match(/^[A-Za-z'-]{1,30}$/);

/** @namespace Scandipwa/Component/Form/Config/validateLastName */
export const validateLastName = ({ value }) => value.length > 0 && value.match(/^[A-Za-z'-]{1,30}$/);

/** @namespace Scandipwa/Component/Form/Config/addressvalidation */
export const addressvalidation = ({ value }) => value.length > 0 && value.match(/^[A-Za-z0-9\s\-,#']{1,200}$/);

/** @namespace Scandipwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: validatePassword,
        message: __('Password should be at least 8 characters long')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    mobileNumber: {
        validate: validatePhone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    validateNumber: {
        validate: validateNumber,
        message: __('Please enter numeric character.')
    },
    validatePinCode: {
        validate: validatePinCode,
        message: __('Please enter a number.')
    },
    validateZipCode: {
        validate: validateZipCode,
        message: __('Pincode should be 6 digits.')
    },
    firstName: {
        validate: validateFirstName,
        message: __('Maximum 30 characters allowed in first name.')
    },
    lastName: {
        validate: validateLastName,
        message: __('Maximum 30 characters allowed in last name.')
    },
    addressvalidation: {
        validate: addressvalidation,
        message: __('Invalid address. Please enter a valid address with a maximum of 200 characters.')
    }
});

export default formConfig();
