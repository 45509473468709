/* eslint-disable max-len */
import {
    UserIcon as SourceUserIcon
} from 'SourceComponent/UserIcon/UserIcon.component';

import './UserIcon.style';

/** @namespace Scandipwa/Component/UserIcon/Component/UserIconComponent */
export class UserIconComponent extends SourceUserIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="UserIcon"
              mods={ { isActive } }
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M7 8C9.21 8 11 6.21 11 4C11 1.79 9.21 0 7 0C4.79 0 3 1.79 3 4C3 6.21 4.79 8 7 8ZM9.8 9H9.278C8.584 9.319 7.812 9.5 7 9.5C6.187 9.5 5.419 9.319 4.722 9H4.2C1.881 9 0 10.881 0 13.2V14.5C0 15.328 0.672 16 1.5 16H12.5C13.328 16 14 15.328 14 14.5V13.2C14 10.881 12.119 9 9.8 9Z" fill="#0B1F3F" />
            </svg>

        );
    }
}

export default UserIconComponent;
