import {
    Form as SourceForm
} from 'SourceComponent/Form/Form.component';

import './Form.override.style';

/** @namespace Scandipwa/Component/Form/Component/FormComponent */
export class FormComponent extends SourceForm {
    // TODO implement logic
}

export default FormComponent;
