/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, _instance) => [
    ...callback(...args),
    new Field('google_analytics_account')
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: {
                position: 100,
                implementation: _getStoreConfigFieldsAction
            }
        }
    }
};
