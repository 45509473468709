import {
    MyAccountTabListItem as SourceMyAccountTabListItem
} from 'SourceComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import './MyAccountTabListItem.override.style';

/** @namespace Scandipwa/Component/MyAccountTabListItem/Component/MyAccountTabListItemComponent */
export class MyAccountTabListItemComponent extends SourceMyAccountTabListItem {
    render() {
        const { tabEntry: [, { name }], isActive } = this.props;

        return (
            <li
              block="MyAccountTabListItem"
              mods={ { isActive } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ this.changeActiveTab }
                  role="link"
                >
                    { name }
                </button>
            </li>
        );
    }
}

export default MyAccountTabListItemComponent;
