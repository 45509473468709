// import PropTypes from 'prop-types';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { LocationType } from 'Type/Common';
import { isSignedIn } from 'Util/Auth';

import MyAccountViewOrder from './MyAccountViewOrder.component';
import { ORDER_ITEMS } from './MyAccountViewOrder.config';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountViewOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    order: state.OrderReducer.order,
    display_tax_in_shipping_amount: state.ConfigReducer.cartDisplayConfig.display_tax_in_shipping_amount
});

/** @namespace Scandipwa/Component/MyAccountViewOrder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    getOrderById: (orderId) => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getOrderById(dispatch, orderId)
    )
});

/** @namespace Scandipwa/Component/MyAccountViewOrder/Container/MyAccountViewOrderContainer */
export class MyAccountViewOrderContainer extends PureComponent {
     static propTypes = {
         location: LocationType.isRequired,
         showNotification: PropTypes.func.isRequired,
         getOrderById: PropTypes.func.isRequired,
         display_tax_in_shipping_amount: PropTypes.string.isRequired
     };

      state = {
          order: {},
          isLoading: true,
          activeTab: ORDER_ITEMS
      };

      containerFunctions = {
          handleChangeActiveTab: this.handleChangeActiveTab.bind(this)
      };

      __construct(props) {
          super.__construct(props);
          const { location: { pathname } } = this.props;

          const pathArray = pathname.split('/');

          const orderId = ((pathArray[pathArray.length - 1]));

          if (orderId) {
              this.requestOrderDetails(orderId);
          }
      }

      handleChangeActiveTab(tab) {
          this.setState({ activeTab: tab });
      }

      containerProps() {
          const {
              order: stateOrder, isLoading, activeTab

          } = this.state;
          const {
              display_tax_in_shipping_amount,
              location
          } = this.props;

          return {
              display_tax_in_shipping_amount,
              isLoading,
              location,
              activeTab,
              order: {
                  ...stateOrder
              }
          };
      }

      async requestOrderDetails(orderId) {
          const { getOrderById } = this.props;

          if (!isSignedIn()) {
              return;
          }

          const Order = await getOrderById(orderId);

          this.setState({ order: Order, isLoading: false });
      }

      render() {
          return (
            <MyAccountViewOrder
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
          );
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountViewOrderContainer);
