import { PropTypes } from 'prop-types';

import {
    FieldInput as SourceFieldInput
} from 'SourceComponent/FieldInput/FieldInput.component';

import './FieldInput.override.style';

/** @namespace Scandipwa/Component/FieldInput/Component/FieldInputComponent */
export class FieldInputComponent extends SourceFieldInput {
    static propTypes = {
        prepone: PropTypes.string
    };

    static defaultProps = {
        formRef: () => {},
        validateSeparately: false,
        isSubmitted: false,
        fileExtensions: '',
        filename: '',
        prepone: ''
    };

    render() {
        const {
            formRef,
            validateSeparately,
            isSubmitted,
            fileExtensions,
            filename,
            prepone,
            ...validProps
        } = this.props;

        return (
            <input
              ref={ formRef }
              validateSeparately={ validateSeparately }
              isSubmitted={ isSubmitted }
              fileExtensions={ fileExtensions }
              filename={ filename }
              prepone={ prepone }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...validProps }
            />
        );
    }
}

export default FieldInputComponent;
