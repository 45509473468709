import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

/** @namespace Scandipwa/Route/SomethingWentWrong/Component/SomethingWentWrongComponent */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {
    // TODO implement logic
}

export default SomethingWentWrongComponent;
