import {
    MyAccountTabList as SourceMyAccountTabList
} from 'SourceComponent/MyAccountTabList/MyAccountTabList.component';

import './MyAccountTabList.override.style';

/** @namespace Scandipwa/Component/MyAccountTabList/Component/MyAccountTabListComponent */
export class MyAccountTabListComponent extends SourceMyAccountTabList {
    // TODO implement logic
}

export default MyAccountTabListComponent;
