/* eslint-disable */

import SlickSlider from 'Component/SlickSlider';

export class Slider extends React.PureComponent {
  constructor(props) {
    super(props);
    const { BaseSlider, Slide } = props.elements;
    this.sliderProps = BaseSlider.propsBag.length > 0 ? BaseSlider.propsBag[0] : {}
    this.slidesProps = Slide.propsBag.length > 0 ? Slide.propsBag : []

    if(this.sliderProps && this.sliderProps.className) {
      const isOneThird = this.sliderProps.className.split(" ").includes('OneThirdSlider');

      if(isOneThird) {
        this.sliderProps['data-center-mode'] = 'true'
        this.sliderProps['data-center-padding'] = '10%'
      }
    }
    }

  renderSlide = (slide, i) => {
    const { Slide } = this.props.elements;

    return (
        <Slide.Ele {...Slide.propsBag[i]}>
          {Slide.childEleBag[i]}
        </Slide.Ele>
    );
  }

  render() {
    const { BaseSlider, Slide } = this.props.elements;

    return <BaseSlider.Ele>
      <SlickSlider
        slidesToShow={1}
        slidesToScroll={1}
        isInfinite={this.sliderProps['data-infinite-loop'] === 'true'}
        isDots={this.sliderProps['data-show-dots'] === 'true'}
        isArrows={ this.sliderProps['data-show-arrows'] === 'true' }
        isCenterMode={ this.sliderProps['data-center-mode'] === 'true' }
        centerPadding={ this.sliderProps['data-center-padding'] }
        isAutoplay={ this.sliderProps['data-autoplay'] === 'true' }
        autoplaySpeed={ this.sliderProps['data-autoplay-speed'] || 4000 }
        isFade={ this.sliderProps['data-fade'] === 'true' }
      >
        {Slide.propsBag.map(this.renderSlide)}
      </SlickSlider>
    </BaseSlider.Ele>
  }
}

export default Slider
