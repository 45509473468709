/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import MyAccountNewReturnAddressForm from './MyAccountNewReturnAddressForm.component';

/** @namespace Rma/Component/MyAccountNewReturnAddressForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    default_country: state.ConfigReducer.default_country,
    addressLinesQty: state.ConfigReducer.address_lines_quantity,
    showVatNumber: state.ConfigReducer.show_vat_number_on_storefront,
    regionDisplayAll: state.ConfigReducer.region_display_all
});

/** @namespace Rma/Component/MyAccountNewReturnAddressForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(showPopup('', {}))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewReturnAddressForm);
