import { STORES } from "../route/StoreFinder/StoreFinder.config";

/** @namespace ScandiPWA/StoreFinder/Plugin/Component/Header/Plugin/stateMap */
export class HeaderComponentPlugin {
    aroundStateMap = (originalMember) => ({
        ...originalMember,
        [STORES]: {
            back: true,
            title: true,
        },
    });
}

const { aroundStateMap } = new HeaderComponentPlugin();

export const config = {
    "Component/Header/Component": {
        "member-property": {
            stateMap: aroundStateMap,
        },
    },
};

export default config;
