// import Field from 'Component/Field';
// import Form from 'Component/Form';
import {
    MyAccountForgotPassword as SourceMyAccountForgotPassword
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';

import './MyAccountForgotPassword.override.style';

/** @namespace Scandipwa/Component/MyAccountForgotPassword/Component/MyAccountForgotPasswordComponent */
export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPassword {

}

export default MyAccountForgotPasswordComponent;
