import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MyAccountOrderTab from './MyAccountOrderTab.component';

/** @namespace Scandipwa/Component/MyAccountOrderTab/Container/MyAccountOrderTabContainer */
export class MyAccountOrderTabContainer extends PureComponent {
    static propTypes = {
        onTabClick: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        tabName: PropTypes.string.isRequired,
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    containerFunctions = {
        handleClickOnTab: this.handleClickOnTab.bind(this)
    };

    containerProps() {
        const { tabName, isActive, title } = this.props;

        return { tabName, isActive, title };
    }

    handleClickOnTab() {
        const { onTabClick, tabName } = this.props;

        onTabClick(tabName);
    }

    render() {
        return (
            <MyAccountOrderTab
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default MyAccountOrderTabContainer;
