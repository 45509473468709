import Html from 'SourceComponent/Html';
import {isString} from '../../helper/functions';

export class HtmlCode extends React.PureComponent {
  render() {
    const {
      BaseHtmlCode
    } = this.props.elements;

    const children = BaseHtmlCode.childEleBag[0]

    if (!!children && (isString(children) || Array.isArray(children))) {
      return (
      <BaseHtmlCode.Ele>
      { isString(children) ? 
          <Html content={ children } /> : 
          children.map((child) => 
          (isString(child) ? <Html content={ child } /> : child)) 
      }
      </BaseHtmlCode.Ele>
      );
    }

    return <BaseHtmlCode.Ele />;
  }
}

export default HtmlCode
