/* eslint-disable max-len */
import OrderQuery from 'Query/Order.query';
import {
    OrderDispatcher as SourceOrderDispatcher
} from 'SourceStore/Order/Order.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { setLoadingStatus } from 'Store/Order/Order.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

/** @namespace Scandipwa/Store/Order/Dispatcher/OrderDispatcher */
export class OrderDispatcher extends SourceOrderDispatcher {
    async cancelOrderById(dispatch, id, item_id, product_qty, bag_id) {
        dispatch(setLoadingStatus(true));

        try {
            // const { cancelOrder: result } = await fetchMutation(OrderQuery.getCancelOrderByIdQuery(id, item_id, product_qty));
            const { CancelItem: result } = await fetchMutation(OrderQuery.getCancelOrderByIdQuery(id, item_id, product_qty, bag_id));
            this.requestOrders(dispatch);

            dispatch(showNotification('success', result.message));
            dispatch(setLoadingStatus(false));
            return result;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            dispatch(setLoadingStatus(false));
            return null;
        }
    }
}

export default new OrderDispatcher();
