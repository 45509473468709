import {
    GET_ORDER_LIST as SOURCE_GET_ORDER_LIST,
    getOrderList as sourceGetOrderList
} from 'SourceStore/Order/Order.action';

export const GET_ORDER_LIST = SOURCE_GET_ORDER_LIST;
export const SET_ORDER_LOADING_STATUS = 'SET_ORDER_LOADING_STATUS';

export const getOrderList = sourceGetOrderList;

/** @namespace Scandipwa/Store/Order/Action/setLoadingStatus */
export const setLoadingStatus = (status) => ({
    type: SET_ORDER_LOADING_STATUS,
    status
});
